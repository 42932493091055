<template>
  <div>
    <b-modal
      modal
      title="TRACKING PO BOX"
      v-model="modalTrackingPoBox"
      size="xmd"
      modal-class="modal-primary"
      @hidden="closeModal"
      title-tag="h3"
      hide-footer
      centered
    >
      <b-table
        ref="refTrackingCallRounds"
        small
        class="mt-2 mb-2"
        table-class="text-nowrap"
        :items="dataTracking"
        :fields="arrayColumns"
        :current-page="paginate.currentPage"
        :per-page="paginate.perPage"
        :busy="isBusy"
        sticky-header="60vh"
        show-empty
        responsive="sm"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading ...</strong>
          </div>
        </template>
        <template #cell(date)="data">
          <span>{{ data.item.date | myGlobalWithHour }}</span>
        </template>

        <template #cell(action)="data">
          <b-badge
            pill
            :variant="data.item.action == 0 ? 'primary' : 'success'"
            >{{ data.item.action == 0 ? "Copied" : "Uploaded" }}</b-badge
          >
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    userDataPoBox: {
      type: Object,
    },
  },
  data() {
    return {
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      toPage: null,
      isBusy: false,
      totalRows: 0,
      modalTrackingPoBox: false,
      dataTracking: [],
      arrayColumns: [
        {
          key: "date",
          label: "Date",
        },
        {
          key: "user",
          label: "User",
        },
        {
          key: "action",
          label: "Action",
        },
      ],
    };
  },
  created() {
    if (this.dataTracking.length == 0) {
      this.dataTracking.push(
        {
          date: this.userDataPoBox.creation_date,
          user: this.userDataPoBox.created_by,
          action: 1,
        },
        {
          date: this.userDataPoBox.copy_date,
          user: this.userDataPoBox.copied_by,
          action: 0,
        }
      );
    }
    this.modalTrackingPoBox = true;
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<style></style>
