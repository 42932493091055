export default [
  {
    key: "zip_code",
    label: "Zip code",
  },
  {
    key: "original_file_name",
    label: "Original File",
  },
  {
    key: "current_file_name",
    label: "Current File",
  },
  {
    key: "client_name",
    label: "Client",
  },
  {
    key: "client_account",
    label: "Account",
  },
  {
    key: "program",
    label: "Program",
  },
  {
    key: "client_folder",
    label: "Folder",
  },
  {
    key: "copied_by",
    label: "Copied by",
  },
  {
    key: "tracking",
    label: "tracking",
  },
];
