<template>
  <div>
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refDeleted'].refresh()"
    >
      <template #table>
        <b-table
          small
          slot="table"
          no-provider-filtering
          ref="refDeleted"
          :items="myProvider"
          :fields="arrayColumns"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(url)="data">
            <div class="cursor-pointer" @click="newPageFile(data.item.url_mod)">
              <amg-icon class="text-danger" icon="FilePdfIcon" />
              {{ data.item.name }}
            </div>
          </template>

          <template #cell(updated_at)="data">
            <div>
              {{ data.item.user_firstname }} {{ data.item.user_lastname }}
            </div>
            <div>{{ data.item.updated_at | myGlobalWithHour }}</div>
          </template>

          <template #cell(action)="data">
            <feather-icon
              @click="openModalSetFilePoBox(data.item)"
              class="cursor-pointer text-primary"
              icon="FileIcon"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-set-file-po-box
      v-if="modalSetFilePoBox"
      :userPoBox="userPoBox"
      @reload="$refs['refDeleted'].refresh()"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import FilePOBoxService from "@/views/commons/components/file-po-box/services/file-po-box.service";
import fields from "@/views/commons/components/file-po-box/components/data/fields.po-box-identify-deleted.data";
import ModalSetFilePoBox from "@/views/commons/components/file-po-box/components/modals/SetFIlePOBox.vue";

export default {
  components: {
    ModalSetFilePoBox,
  },
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by zip code...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      toPage: null,
      isBusy: false,
      filter: [],
      totalRows: 0,
      arrayColumns: fields,
      modalSetFilePoBox: false,
      userPoBox: {},
    };
  },
  methods: {
    newPageFile(url) {
      window.open(url, "_blank");
    },
    async myProvider(ctx) {
      try {
        const params = {
          page: this.paginate.currentPage,
          perPage: this.paginate.perPage,
          orderby: ctx.sortDesc == 1 ? "asc" : "desc",
          order: 3,
          campo: this.filterPrincipal.model,
          status: 0,
          deleted: 1,
        };
        const data = await FilePOBoxService.getPOBoxes(params);
        if (data.status == 200) {
          const items = data.data.data;
          this.startPage = data.data.from;
          this.currentPage = data.data.current_page;
          this.perPage = data.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = data.data.last_page;
          this.totalRows = data.data.total;
          this.toPage = data.data.to;
          return items || [];
        }
      } catch (e) {
        console.log(e);
      }
    },
    openModalSetFilePoBox(item) {
      this.userPoBox = item;
      this.modalSetFilePoBox = true;
    },
    closeModal() {
      this.modalSetFilePoBox = false;
    },
  },
};
</script>

<style></style>
