<template>
  <div>
    <filter-slot
      :filter="filters"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refVerified'].refresh()"
    >
      <template #table>
        <b-table
          small
          slot="table"
          no-provider-filtering
          ref="refVerified"
          :items="myProvider"
          :fields="arrayColumns"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(original_file_name)="data">
            <div class="cursor-pointer" @click="newPageFile(data.item.url_mod)">
              <amg-icon class="text-danger mr-1" icon="FilePdfIcon" />
              <a href="#">{{ data.item.original_file_name }}</a>
            </div>
          </template>

          <template #cell(current_file_name)="data">
            <div class="cursor-pointer" @click="newPageFile(data.item.url_mod)">
              <amg-icon class="text-danger mr-1" icon="FilePdfIcon" />
              <a href="#">{{ data.item.current_file_name }}</a>
            </div>
          </template>

          <template #cell(copied_by)="data">
            <div>{{ data.item.copied_by }}</div>
            <div>{{ data.item.copy_date | myGlobalWithHour }}</div>
          </template>

          <template #cell(tracking)="data">
            <feather-icon
              @click="openModalTrackingPoBox(data.item)"
              class="text-success cursor-pointer"
              icon="ListIcon"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>

    <modal-tracking-po-box
      v-if="modalTrackingPoBox"
      :userDataPoBox="userDataPoBox"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import FilePOBoxService from "@/views/commons/components/file-po-box/services/file-po-box.service";
import filters from "@/views/commons/components/file-po-box/components/data/filters.po-box-verified.data";
import fields from "@/views/commons/components/file-po-box/components/data/fields.po-box-verified.data";

//modals
import ModalTrackingPoBox from "@/views/commons/components/file-po-box/components/modals/TrackingPOBox.vue";
export default {
  components: {
    ModalTrackingPoBox,
  },
  data() {
    return {
      userDataPoBox: {},
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search by zip code...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      startPage: null,
      toPage: null,
      isBusy: false,
      filter: [],
      totalRows: 0,
      arrayColumns: fields,
      filters: filters,
      modalTrackingPoBox: false,
    };
  },
  async created() {
    await this.getProgram();
    if (this.filters[2].options[0].value != "all") {
      this.filters[2].options.unshift({
        id: 0,
        initial: "A",
        name: "All",
        value: "All",
      });
    }
  },
  methods: {
    newPageFile(url) {
      window.open(url, "_blank");
    },
    async getProgram() {
      const data = await FilePOBoxService.programs();
      this.filters[2].options = data.data;
    },
    async myProvider(ctx) {
      try {
        const params = {
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          pobox_id: "",
          orderby: ctx.sortDesc == 1 ? "asc" : "desc",
          order: 3,
          campo: this.filterPrincipal.model,
          id_program: this.filters[2].model,
          from: this.filters[0].model,
          to: this.filters[1].model,
        };
        const data = await FilePOBoxService.getVerifiedPOBox(params);
        if (data.status == 200) {
          const items = data.data.data;
          this.startPage = data.data.from;
          this.currentPage = data.data.current_page;
          this.perPage = data.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = data.data.last_page;
          this.totalRows = data.data.total;
          this.toPage = data.data.to;

          return items || [];
        }
      } catch (e) {
        console.log(e);
      }
    },
    openModalTrackingPoBox(data) {
      this.userDataPoBox = data;
      this.modalTrackingPoBox = true;
    },
    closeModal() {
      this.modalTrackingPoBox = false;
    },
  },
};
</script>

<style></style>
